@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600;700&family=Kumbh+Sans&family=Red+Hat+Text:wght@700&display=swap');

.HomeWrap {
    background-image: url(../static/bg-stars.svg);
    width: 100vw;
    height: 100vh;
    font-family: 'Red Hat Text';
    z-index: 0;
}
.HomeWrap h4 {
    margin-top: 7.5%;
}
.timerWrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin-left: 25%;
    height: 10%;
}
.timerWrap div {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
}
.timerSubText {
    color: hsl(237, 18%, 59%);
    font-size: 14px;
}
.socialSection {
    width: 20%;
    margin: 25% 40%;
    display: flex;
    justify-content: space-evenly;
}
.svg {
    fill: currentColor;
    z-index: 1;
}
.svg:hover {
    fill: hsl(345, 95%, 68%);
    stroke:hsl(345, 95%, 68%);
    cursor: pointer;
}
.Mtn {
    position: fixed;
    width: 100%;
    bottom:0;
    left: 0;
    z-index: -1;
}
.timeBG {
    color:hsl(345, 95%, 68%);
    background-color: hsl(236, 21%, 26%);
    width: 85px;
    min-height:85px;
    border-radius: .25em;
    box-shadow: 0px 10px 10px black;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 400px) {
    .socialSection {
        width: 30%;
        margin: 25% 35%;
    }
    .Mtn {
        height: 30%;
    }
    .timerWrap {
        width: 90%;
        margin: 5%;
    }
    .timeBG {
        width: 60px;
        min-height: 60px;
    }
}